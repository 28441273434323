import React from 'react';
import { Form, Modal, Table } from 'antd'; // Removed unnecessary imports
import useAuth from '../../hooks/useAuth';

export default function EventHistoryModal({
  visible, // Changed prop name to 'visible'
  onCancel,
  eventHistoryData = {},
  checkIsValidAddition,
  masterData,
}) {
  const { userConfig } = useAuth();
  const formRef = React.useRef();

  const orgId = userConfig.orgId;

  // Calculate the width of each column based on the content
  const getColumnWidth = (data, dataIndex) => {
    if (!Array.isArray(data) || data.length === 0) {
      return 100; // Return a default width if data is not an array or empty
    }

    return Math.max(
      ...data.map((item) => item[dataIndex]?.toString().length * 10),
      100
    );
    // return Math.max(...data.map(item => item[dataIndex].toString().length * 10), 100);
  };

  // Function to convert number of days since reference date to a date string
  // Function to format a date string to DD-MM-YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const sortedEventHistoryData = eventHistoryData.sort(
    (a, b) => new Date(b.effDate) - new Date(a.effDate)
  );

  const processedData = sortedEventHistoryData.map((item, index, array) => ({
    ...item,
    displayDepartmentCode: item.departmentCode1 || item.departmentCode,
    // previousPSNumber: index > 0 ? array[index - 1].psNumber : null,
  }));

  //For Description

  React.useEffect(() => {
    cadreCodeMasterInput();
  }, [masterData]);

  const [cadreCodeMaster, setCadreCodeMaster] = React.useState([]);
  const [drmr, setDrmr] = React.useState([]);

  function cadreCodeMasterInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Cadre_Master_Regular';
    //
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );

    setDrmr(dataFromArray?.dataParsed);
    const functionCadreCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //
        return item?.Cadre_Code + ' ' + item?.Cadre_Description;
        // return item?.Cadre_Description;
      }
    );
    //
    setCadreCodeMaster(functionCadreCodeMasterInputAreas);
    //

    if (dataFromArray) {
      console.log(
        'functionCadreCodeMasterInputAreas',
        functionCadreCodeMasterInputAreas
      );
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  const codeDescriptionMap = {};
  console.log('cadreCodeMaster455', cadreCodeMaster);
  cadreCodeMaster?.forEach((area) => {
    const [code, ...descriptionParts] = area.split(' ');
    const description = descriptionParts.join(' ');
    codeDescriptionMap[code] = description;
  });

  const columns = [
    {
      title: 'Eff. Date',
      dataIndex: 'effDate',
      key: 'effDate',
      //   width: getColumnWidth(eventHistoryData, 'effDate'),
      //   render: (effDate) => formatDateFromDays(effDate),
      align: 'center',
      render: (effDate) => formatDate(effDate),
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      //   width: getColumnWidth(eventHistoryData, 'event'),
      align: 'center',
    },
    // {
    //   title: 'From Cadre',
    //   dataIndex: 'fromCadre',
    //   key: 'fromCadre',
    //   //   width: getColumnWidth(eventHistoryData, 'fromCadre'),
    //   align: 'center',
    // },
    {
      title: 'From Cadre',
      dataIndex: 'fromCadre',
      key: 'fromCadre',
      align: 'center',
      render: (code) => {
        // Use the map to get the description from the code
        const fromCadreDescription = codeDescriptionMap[code] || code;
        return fromCadreDescription; // Return the description or the code if not found
      },
    },

    // {
    //   title: 'To Cadre',
    //   dataIndex: 'toCadre',
    //   key: 'toCadre',
    //   //   width: getColumnWidth(eventHistoryData, 'toCadre'),
    //   align: 'center',
    // },
    {
      title: 'To Cadre',
      dataIndex: 'toCadre',
      key: 'toCadre',
      align: 'center',
      render: (code) => {
        // Get the description for the 'toCadre' code
        const toCadreDescription = codeDescriptionMap[code] || code;
        return toCadreDescription; // Return the description or code if not found
      },
    },
    {
      title: 'Department Code',
      dataIndex: 'displayDepartmentCode',
      key: 'displayDepartmentCode',
      align: 'center',
    },

    {
      title: 'Function Code',
      dataIndex: 'functionCode',
      key: 'functionCode',
      //   width: getColumnWidth(eventHistoryData, 'functionCode'),
      align: 'center',
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
      //   width: getColumnWidth(eventHistoryData, 'functionCode'),
      align: 'center',
    },
    {
      title: 'Basic',
      dataIndex: 'basic',
      key: 'basic',
      //   width: getColumnWidth(eventHistoryData, 'functionCode'),
      align: 'center',
    },
    {
      title: 'Previous PS Number',
      dataIndex: 'psNumber',
      key: 'psNumber',
      align: 'center',
    },
  ];

  const _onCancel = () => {
    onCancel();
  };

  return (
    <Modal
      title='Event History'
      visible={visible}
      onCancel={onCancel}
      //   okText="Save"
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      autoComplete='off'
      width='1000px'>
      {/* Your modal content */}
      <Form name='admin' layout='vertical'>
        {/* Your existing form fields */}
      </Form>

      {/* Render the table */}
      <Table
        // dataSource={eventHistoryData}
        // dataSource={sortedEventHistoryData}
        dataSource={processedData}
        columns={columns}
        pagination={false}
        bordered
        scroll={{ y: 300 }}
        // style={{ width: '100%', minWidth: '800px'}}
      />
    </Modal>
  );
}
