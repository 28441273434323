import React, { useState, useEffect } from 'react';
import {
  Button,
  Upload,
  message,
  notification,
  Row,
  Col,
  Form,
  Typography,
  Select,
  Modal,
  Table,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import useAuth from '../hooks/useAuth';
import { uploadBulkDataApi } from '../services/auth';

// import XLSX from "xlsx";

import axios1 from '../../src/services/axiosInstance';
import dayjs from 'dayjs';
import { setLoader } from '../actions';
const XLSX = require('xlsx');
const { Title } = Typography;
const { Option } = Select;

const FileUploader = () => {
  const [fileList, setFileList] = useState([]);
  const [location, setLocation] = useState({});
  const [uploaddocumentResponse, setuploaddocumentResponse] = useState([]);
  const navigate = useNavigate();
  const { user, userConfig } = useAuth();

  console.log('location Id36', userConfig.locationId);

  useEffect(() => {
    EmployeeList1();
  }, []);

  const EmployeeList1 = async () => {
    const Response = await axios1.get('/org/locations');
    const Response2 = Response.data.org.locations;

    setLocation({ Response2 });
  };

  const handleFileChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1); // Allow only one file
    setFileList(fileList);
  };

  const formatField = (item, locationId) => {
    const dob = dayjs(item['DOB (as per Aadhaar)'], 'DD/MM/YYYY').format(
      'YYYY-MM-DDTHH:mm:ss.SSSZ'
    );

    const dobDate = new Date(
      1900,
      0,
      1 + parseInt(item['DOB (as per Aadhaar)']) - 1
    );

    // Format the date using dayjs
    // const dob = dayjs(dobDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    const dojDate = new Date(
      1900,
      0,
      1 + parseInt(item['Date of Joining']) - 1
    );

    // Format the date using dayjs
    const doj1 = dayjs(dojDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

    // const doj = dayjs(item['Doj'], 'DD/MM/YYYY').format(
    //   'YYYY-MM-DDTHH:mm:ss.SSSZ'
    // );
    const age = calculateAge(dob);

    let workerCode = String(item['Worker Code']) || ''; // Ensure ContractorCode is a string, if it's null or undefined, set it to an empty string
    const desiredLength11 = 8;

    // Check if ContractorCode length is less than 4
    if (workerCode.length < desiredLength11) {
      const numZerosToAdd = desiredLength11 - workerCode.length;
      // Add leading zeros
      workerCode = '0'.repeat(numZerosToAdd) + workerCode;
    }

    const personalDetails = {
      firstName: item['First Name'],
      middleName: item['Middle Name'],
      lastName: item['Last Name'],
      LongName: item['Long Name'],

      gender: item['Gender'],
      fatherName: item['Father/Guardian Name'],
      Dob: dayjs(item['Date Of Birth'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      mobile: String(item['Mobile No.']),
      alternateMobile: String(item['Alternate Contact No.']),
      age: item['Age'],
      aadharNo: item['Aadhar_Number'],
      martialStatus: item['Marital Status'],
      spousename: item['Spouse Name'],
      childrenNo: String(item['Number of Children']),
      emergencyContactNo: String(item['Emergency Contact No.']),
      emergencyContactName: item['Emergency Contact Name'],
      idmark: item['ID Mark'],
      religion: item['Religion'],
      domicile: item['Domicile'],
      height: item['Height'],
      numberOfYearSpent: item['No. of Years spent in State'],
      pan: item['PAN'],
      rationcardno: String(item['Ration Card Number']),
      address1: item['Present Address Line1'] || '---',
      address2: item['Present Address Line2'] || '---',
      address3: item['Present Address Line3'] || '---',

      village: item['Local Village/Mandal'],
      district: item['Local District'],
      pincode: String(item['Pincode']),
      village: item['Local Village/Mandal'],
      state: item['Local State'],
      tehsil: item['Local Tehsil'],
      taluka: item['Local Taluka'],
      city: item['Local City'],
      country: item['Country'],
      //--------------
      paddress1: item['Permanent Address Line1'],
      paddress2: item['Permanent Address Line2'],
      paddress3: item['Permanent Address Line3'],

      pvillage: item['Permanent Village/Mandal'],
      pstate: item['Permanent State'],
      pdistrict: item['Permanent District'],
      ppincode: String(item['Pincode']),
      ptaluka: item['Permanent Taluka'],
      ptehsil: item['Permanent Tehsil'],
      pcity: item['Permanent City'],
      pcountry: item['Country'],
      //Professional Details
      empCode: workerCode,
      empCode: item['Worker Code'],
      epnNumber: item['EPN Code'],
      epnRenew: dayjs(item['EPN Renew'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),

      locationId: item['Location ID'],
      hazardousProcessArea: item['Hazardous Process Area'],
      technicalDetails: item['Technical Details'],
      natureOfWork: item['Type of Contract'],
      // gateNumber: String(item['Gate No.']),
      gateNumber: item['Gate No.'],

      disabilityType: item['Disability Type'],
      disabilityPercentage: item['Disability Percentage'],
      // safetyTrainingFromDate: dayjs(
      //   item['Safety Training From Date'],
      //   'DD/MM/YYYY'
      // ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      // safetyTrainingUptoDate: dayjs(
      //   item['Safety Training Upto Date'],
      //   'DD/MM/YYYY'
      // ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      // safetyTrainingRemarks: item['Safety Training Remarks'],
      blockRemark: item['Block Remarks'],
      workingArea: item['Working Area'],
      policeVerification: item['Police Verification'],

      esicno: item['IP_ESIC_Number'],
      uan: item['UAN_Number'] ? item['UAN_Number'] : ' ',
      pfAccountNo: item['PF Account No.'],
      locationId: locationId,
      unitName: item['Unit Name'],
      votercardno: item['Voter Card No.'] ? '' : ' ',
    };

    const jobDetails = {
      // employerCode: String(item['Principal Employer Code']),
      contractorCode: String(item['Contractor Code']),
      contractorName: item['Contractor Name'],
      departmentCode: item['Dept Code'],
      workingArea: item['Working Area'],
      // sectionCode: String(item['Section Code']),
      sectionCode: item['Section Code'],

      // workorderCode: String(item['Workorder Number']),
      workorderCode: item['Workorder Number'],

      itemServiceCode: String(item['Item Service Code']),
      labourType: item['Employee Type'],
      jobOrderNumber: item['JO Number'],
      // skillCode: String(item['Skill Code']),
      skillCode: item['Skill Code'],

      subSkillCode: String(item['Subskill Code']),
      subSkillDate: dayjs(item['Subskill Date'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      workType: item['Work Type'],
      designation: item['Worker Type'],
      duration: item['Duration'],
      workerCategory: item['Worker Category'],
      terminationReason: item['Reason for Termination'],
      //contractorReferenceNumber: String(item['Contractor Ref No.']),
      doj: dayjs(item['Date of Joining'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      // doj: doj,

      totalExperience: String(item['Total Experience']),
      previousOrg: item['Previous Organization'],
      referredBy: item['Refered By'],
      name: item['Relative Name'],
      mobileNo: item['Relative Mobile Number'],
      bankname: item['Bank Name'],
      AccountNo: String(item['Bank Account No.']),
      ifsc: item['IFSC Code'],
      userName: item['Name as per Bank Account'],
      bloodGroup: item['Blood Group'],
      techQualification: item['Technical Qualification'],
      academicQualification: item['Academic Qualification'],
      shoeSize: String(item['Shoe Size']),
      dl: String(item['DL No.']) ? '' : ' ',
      dlExpiry: dayjs(item['DL Validity Date'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      )
        ? ''
        : ' ',
    };
    const wageDetails = {
      basic: String(item['Basic']),
      da: String(item['DA']),
      allowance1: String(item['Allowance1']),
      allowance2: String(item['Allowance2']),
      hra: String(item['HRA']),
      conveyance: String(item['Conveyence']),
      specialAllowance: String(item['Special Allowance']),
      medicalAllowance: String(item['Medical Allowance']),
      eduAllowance: String(item['Education Allowance']),
      travelAllowance: String(item['Leave/Travel Allowance']),
      ot: item['OT Applicable'],
      effectiveDate: dayjs(item['Wage Effective Date'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
    };
    const uploadDocument = {
      annualMedicalCheckupDate: dayjs(
        item['Next annual Medical Checkup Date'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      // documentIssuedBy: String(item['Document Issued By']),
    };

    const payload = {
      personalDetails,
      jobDetails,
      //wageDetails,
      uploadDocument,
      locationId,
      employementType: 'Contractor',
    };
    return payload;
  };

  const removeDuplicatesAndNullsByFieldsAndStructureData = (
    data,
    fields,
    locationId
  ) => {
    const seen = new Set();
    const uniqueItems = [];
    const duplicateItems = [];

    data.forEach((item) => {
      const values = fields?.map((field) => item[field]);

      // Check if any of the values is null
      if (values.includes(null)) {
        return; // Skip objects with null values
      }

      const key = values.join('|');

      const formatedValue = formatField(item, locationId);

      // Check for duplicates
      if (!seen.has(key)) {
        seen.add(key);
        uniqueItems.push(formatedValue);
      } else {
        duplicateItems.push(formatedValue);
      }
    });

    return { uniqueItems, duplicateItems };
  };
  const calculateAge = (dob) => {
    const currentDate = dayjs(); // Get the current date
    const birthDate = dayjs(dob, 'YYYY-MM-DDTHH:mm:ss.SSSZ'); // Parse the DOB string into a dayjs object

    const age = currentDate.diff(birthDate, 'year'); // Calculate the difference in years

    return age;
  };
  //New one
  const onFinish = async (value) => {
    const file = fileList[0];

    if (!file) {
      message.error('No file uploaded');
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);

      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const jsonData = XLSX.utils.sheet_to_json(
        workbook.Sheets[firstSheetName]
      );

      const fieldsToCheck = ['Aadhar_Number', 'UAN_Number', 'IP_ESIC_Number'];

      const { uniqueItems, duplicateItems } =
        removeDuplicatesAndNullsByFieldsAndStructureData(
          jsonData,
          fieldsToCheck,
          value.locationId
        );

      setLoader(true);

      const response = await uploadBulkDataApi(uniqueItems);
      setLoader(false);

      // Check if failedData is empty
      if (response.data.failedData.length === 0) {
        notification.success({
          message: 'Employee onboarded Successfully.',
        });
        navigate('/home/supervisor/employee');
        return; // Do not show modal if it's empty
      }

      setuploaddocumentResponse(
        response.data?.failedData?.map((row) => {
          return {
            firstName: row.personalDetails.firstName,
            lastName: row.personalDetails.lastName,
            aadharNo:
              row.aadharDuplicate === true
                ? row.personalDetails.aadharNo
                : 'NA',
            pfAccountNo:
              row.isPfDuplicate === true
                ? row.personalDetails.pfAccountNo
                : 'NA',
            esicno: row.personalDetails.esicno,
            Reason:
              row.aadharDuplicate === true
                ? 'Aadhaar Number is duplicate'
                : 'PF/UAN Number is duplicate',
          };
        })
      );
    };

    reader.readAsArrayBuffer(file.originFileObj);
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Aadhaar Number',
      dataIndex: 'aadharNo',
      key: 'aadharNo',
    },
    {
      title: 'PF Number',
      dataIndex: 'pfAccountNo',
    },
    {
      title: 'ESI Number',
      dataIndex: 'esicno',
    },
    {
      title: 'Reason',
      dataIndex: 'Reason',
    },
  ];

  const handleDownload = async () => {
    try {
      //const response = await fetch('http://localhost:3000/api/download-excel', {
      //const response = await fetch(
      // 'https://vyn.wikiworks.in:443/api/download-excel',
      // {
      const response = await fetch(
        // 'https://demo.wikiworks.in:443/api/download-excel',
        `${process.env.REACT_APP_API_URL}/download-excel`,
        {
          method: 'GET',
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        saveAs(blob, 'Template.xlsx');
      } else {
        console.error('Failed to download Excel sheet');
      }
    } catch (error) {
      console.error('Failed to download Excel sheet', error);
    }
  };

  const handleCancel = () => {
    setuploaddocumentResponse([]);
    navigate('/home/supervisor/employee');
  };

  return (
    <>
      <Row>
        <Title level={5} style={{ fontWeight: 'bolder' }}>
          Bulk Upload
        </Title>
      </Row>
      <Form layout='vertical' onFinish={onFinish}>
        <Row>
          <Col span={6}>
            <Form.Item name='dot' label='Download Template'>
              <Button type='primary' onClick={handleDownload}>
                Download Template
              </Button>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name='locationId'
              label='Location Id'
              rules={[{ required: true, message: 'Enter LocationID' }]}>
              {/* <Select style={{ width: '60%' }}>
                {location.Response2?.slice(1).map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.id}
                  </Option>
                ))}
              </Select> */}
              <Select style={{ width: '60%' }}>
                {/* {location.Response2?.slice(1).map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.id}
                  </Option>
                ))} */}
                <Option value={userConfig.location}>
                  {userConfig.location}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label='Upload Bulk Documents' name='bok'>
              <Upload
                fileList={fileList}
                onChange={handleFileChange}
                beforeUpload={() => false}
                accept='.xlsx'
                rules={[{ required: true, message: 'Select Upload' }]}>
                <Button disabled={fileList.length === 1}>
                  Select Bulk Upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='upload' label='Upload Bulk Data'>
              <Button
                type='primary'
                disabled={fileList.length === 0}
                htmlType='submit'>
                Upload Bulk Data
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Modal
          title='Failed Data'
          open={uploaddocumentResponse.length}
          onOk={handleCancel}
          onCancel={handleCancel}
          footer={
            <Button type='primary' onClick={handleCancel}>
              OK
            </Button>
          }
          maskClosable={true}
          width={1000}>
          <Table
            columns={columns}
            dataSource={uploaddocumentResponse}
            pagination={true}
          />
        </Modal>
      </Form>
    </>
  );
};

export default FileUploader;
