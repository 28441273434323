import React, { useState } from 'react';
import {
  Button,
  Form,
  Upload,
  Col,
  Typography,
  Input,
  Divider,
  notification,
  Modal,
  DatePicker,
  Row,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { uploadFileMedical } from '../../services/auth';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Email } from '../../services/auth';
import axios1 from '../../services/axiosInstance';

import useAuth from '../../hooks/useAuth';
import dayjs from 'dayjs';

import { Approved1 } from '../../services/auth';
const { Title } = Typography;
const { TextArea } = Input;
// const { Option } = Select;

const Medical = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user, userConfig } = useAuth();

  //Get Organization Info
  const [employeeDetails, setemployeeDetails] = React.useState();
  const [jobDetails, setjobDetails] = React.useState();
  React.useEffect(() => {
    OrganizationInfo();
  }, []);
  const OrganizationInfo = async () => {
    const response = await axios1.get(`pass/details?id=${id}`);

    const Normaldata = response.data.emp.personalDetails;
    const jobDetails = response.data.emp.jobDetails;
    const formattedDOB = dayjs(Normaldata.dob).format('YYYY-MM-DD');
    const formattedDOJ = dayjs(jobDetails.doj).format('YYYY-MM-DD');

    setemployeeDetails({
      ...Normaldata,
      dob: formattedDOB,
      ...jobDetails,
      approvers: response?.data?.emp?.approvers,
      exceptions: response?.data?.emp?.exceptions,
      status: response?.data?.emp?.status,
    });
    setjobDetails({
      ...jobDetails,
      // doj:formattedDOJ
    });
  };

  const onFinish = (values) => {
    navigate('/home/approver/list');

    // const file = values?.originalFileName[0]?.originFileObj;
    const file = values?.originalFileName?.originFileObj;

    let formData = new FormData();
    formData.append('file', file);

    const data = {
      formData,
      id: id,
      reason: values.reason,
      status: 'approved',
      designation: jobDetails?.designation,
      approvers: employeeDetails?.approvers,
      exceptions: employeeDetails?.exceptions,
      pmeDonedate: values?.pmeDonedate,
      pmeValidityDate: values?.pmeValidityDate,
    };
    if (values?.originalFileName) {
      uploadFileMedical(formData)
        .then((res) => {
          // notification.success({ message: res.data.message });
        })
        .catch((err) => {
          // notification.error({ message: err.response.data.message });
        });
    }

    Approved1(data)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    Email({
      workmenName: userConfig.firstName + ' ' + userConfig.lastName,
      templateId: 'WORKMEN_APPROVED_FOR_SAFETY_TEST',
      orgId: userConfig.orgId,
      role: userConfig.role,
      level: 3,
      locationId: userConfig.locationId,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };

  const Reject = () => {
    navigate('/home/approver/list');

    const data = {
      id: id,
      status: 'rejected',
      reason: onFinish.reason,
    };
    Approved1(data)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    Email({
      templateId: 'RECORD_RESUBMITTED',
      // VYNApplication: 'https://demo.wikiworks.in/login',
      VYNApplication: process.env.REACT_APP_URL,
      orgId: userConfig.orgId,
      level: userConfig.level,
      role: userConfig.role,
      locationId: userConfig.locationId,
      correctRecipientEmail: userConfig.emailId,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewOpen, setPreviewOpen] = useState(true);

  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => setPreviewOpen(false);

  return (
    <div>
      <Form layout='vertical' onFinish={onFinish} enctype='multipart/form-data'>
        <Title level={4}>Upload Document</Title>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label='Medical Test Certificate'
              name='originalFileName'
              valuePropName='fileList'
              getValueFromEvent={normFile}
              type='file'
              // rules={[{ required: true, message: 'Upload Document' }]}
            >
              <Upload
                listType='picture'
                type='file'
                onPreview={handlePreview}
                maxCount={3}
                beforeUpload={(f, fl) => {
                  return false;
                }}>
                <Button icon={<UploadOutlined />}>
                  Medical Test Certificate
                </Button>
              </Upload>
            </Form.Item>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}>
              <img
                alt='example'
                style={{
                  width: '100%',
                }}
                src={previewImage}
              />
            </Modal>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item
              name='pmeDonedate'
              label='PME Done Date'
              rules={[{ required: true, message: 'Enter PME Done Date' }]}>
              <DatePicker
                size='large'
                style={{ width: '100%' }}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='pmeValidityDate'
              label='PME Validity Date'
              rules={[{ required: true, message: 'Enter PME Validity Date' }]}>
              <DatePicker
                size='large'
                style={{ width: '100%' }}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />

        <Col xs={54} lg={12}>
          <Form.Item
            name='reason'
            label='FeedBack'
            rules={[{ required: true, message: 'Enter Feedback' }]}>
            <TextArea placeholder='Enter FeedBack' allowClear />
          </Form.Item>
        </Col>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button
            disabled={employeeDetails?.status == 'rejected'}
            type='primary'
            htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button onClick={(evt) => Reject(evt)}>Reject</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Medical;
