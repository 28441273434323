import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Upload,
  Col,
  Typography,
  Input,
  Divider,
  notification,
  Modal,
  Row,
  DatePicker,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { uploadFileSafety } from '../../services/auth';
import { Approved1 } from '../../services/auth';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Email } from '../../services/auth';
import useAuth from '../../hooks/useAuth';
import axios1 from '../../services/axiosInstance';
import axios from 'axios';
import { accesstoken } from '../../services/auth';
import { postdata } from '../../services/auth';
import dayjs from 'dayjs';

const { Title } = Typography;
const { TextArea } = Input;
// const { Option } = Select;

const username = process.env.REACT_APP_username;
const pwd = process.env.REACT_APP_password;
const clientId = process.env.REACT_APP_client_id;
const clientSecret = process.env.REACT_APP_client_secret;
const grantType = process.env.REACT_APP_grant_type;

const Safety = () => {
  const navigate = useNavigate();
  const { user, userConfig } = useAuth();
  const [formData1, setFormData1] = useState({
    username: username,
    password: pwd,
    client_id: clientId,
    client_secret: clientSecret,
    grant_type: grantType,
  });
  const [employeeDetails, setemployeeDetails] = React.useState();
  const [jobDetails, setjobDetails] = React.useState();
  const [emailIdDetail, setEmailIdDetail] = React.useState();

  const [Request, setRequest] = useState({});
  const [errorHandling, setErrorHandling] = useState({});

  const { id } = useParams();

  //Get Organization Info
  React.useEffect(() => {
    OrganizationInfo();
  }, []);
  const OrganizationInfo = async () => {
    const response = await axios1.get(`pass/details?id=${id}`);
    const userSupervisor = await axios1.get('/user/info');
    const allUsers = await axios1.get('/supervisorlist');
    const Normaldata = response.data.emp.personalDetails;

    const jobDetails = response.data.emp.jobDetails;

    const formattedDOB = dayjs(Normaldata.dob).format('YYYY-MM-DD');
    const formattedDOJ = dayjs(jobDetails.doj).format('YYYY-MM-DD');

    allUsers.data.supervisorList.forEach((list) => {
      if (list.userid === response.data.emp.createdBy) {
        setEmailIdDetail(list.emailId);
      }
    });

    setemployeeDetails({
      ...Normaldata,
      dob: formattedDOB,
      ...jobDetails,
      approvers: response?.data?.emp?.approvers,
      exceptions: response?.data?.emp?.exceptions,
    });
    setjobDetails({
      ...jobDetails,
      // doj:formattedDOJ
    });
  };

  const onFinish = async (values) => {
    //
    navigate('/home/approver/list');

    // const file = values?.originalFileName[0]?.originFileObj;

    // let formData = new FormData();
    // formData.append('file', file);

    // uploadFileSafety(formData)
    //   .then((res) => {
    //     // notification.success({ message: res.data.message });
    //   })
    //   .catch((err) => {
    //     // notification.error({ message: err.response.data.message });
    //   });

    // const data = {
    //   originalFileName: 'Add Supplier.png',
    //   storageFileName: '9a5121de-8130-46f9-a723-4d3f891d1cd5.png',
    //   url: 'https://olouat.blob.core.windows.net/olouatdocs/9a5121de-8130-46f9-a723-4d3f891d1cd5.png',
    //   id: id,
    //   reason: values.reason,
    //   status: 'approved',
    //   safetyCerticationEndDate: values?.safetyCerticationEndDate,
    //   designation: jobDetails?.designation,
    //   approvers: employeeDetails?.approvers,
    //   exceptions: employeeDetails?.exceptions,
    // };

    const data = {
      originalFileName: 'Add Supplier.png',
      storageFileName: '9a5121de-8130-46f9-a723-4d3f891d1cd5.png',
      url: 'https://olouat.blob.core.windows.net/olouatdocs/9a5121de-8130-46f9-a723-4d3f891d1cd5.png',
      id: id,
      // reason: values.reason,
      status: 'approved',
      facultyName: values?.facultyName,
      reason: values?.reason,
      safetyTrainingFromDate: values?.safetyTrainingFromDate,
      safetyTrainingToDate: values?.safetyTrainingToDate,
      safetyTrainingValidFromDate: values?.safetyTrainingValidFromDate,
      safetyCerticationEndDate: values?.safetyCerticationEndDate,
      designation: jobDetails?.designation,
      approvers: employeeDetails?.approvers,
      exceptions: employeeDetails?.exceptions,
    };

    Approved1(data)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        // notification.error({ message: 'Employee data sync with WFD' });
      });

    Email({
      workmenName: employeeDetails?.firstName + ' ' + employeeDetails?.lastName,
      templateId: 'APPROVER_DONE',
      orgId: userConfig.orgId,
      role: 'supervisor',
      level: null,
      locationId: null,
      correctRecipientEmail: emailIdDetail,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    //Wfd Integration
    //Get Access Token
    accesstoken(employeeDetails)
      .then((res) => {
        notification.success({ message: 'Data synched with WFD' });
        const requestId = res?.data?.responseObject?.requestId;
        setRequest({ requestId });
      })
      .catch((err) => {
        setErrorHandling({ err });

        // notification.error({ message: err.response.data.error });
      });

    postdata()
      .then((res) => {})
      .catch((err) => {
        // notification.error({ message: "Wfd integrating successfully" });
      });
  };
  const Reject = () => {
    navigate('/home/approver/list');

    const data = {
      id: id,
      status: 'rejected',
      reason: onFinish.reason,
      designation: jobDetails.designation,
    };
    Approved1(data)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    Email({
      templateId: 'RECORD_RESUBMITTED',
      // VYNApplication: 'https://demo.wikiworks.in/login',
      VYNApplication: process.env.REACT_APP_URL,
      orgId: userConfig.orgId,
      level: userConfig.level,
      role: userConfig.role,
      locationId: userConfig.locationId,
      correctRecipientEmail: userConfig.emailId,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewOpen, setPreviewOpen] = useState(true);

  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => setPreviewOpen(false);

  return (
    <div>
      <Form layout='vertical' onFinish={onFinish} enctype='multipart/form-data'>
        <Title level={4}>Upload Document</Title>
        <Row>
          {/*
          <Col span={6}>
            <Form.Item
              name='safetyCerticationEndDate'
              label='Safety Certication End Date'
              rules={[
                {
                  required: true,
                  message: 'Enter Safety Certication End Date',
                },
              ]}>
              <DatePicker
                size='large'
                placeholder='Safety Certication End Date'
                style={{ width: '80%' }}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col> */}

          <Col className='gutter-row' span={6}>
            <Form.Item
              // name='contractorName'
              label='Contractor Name'
              rules={[{ required: true, message: 'Enter Contractor Name' }]}>
              <Input
                size='large'
                // style={{ width: '80%' }}
                value={jobDetails?.contractorName}
                disabled
                style={{ fontWeight: 'bold', color: 'gray', width: '80%' }}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='facultyName'
              label='Faculty Name'
              rules={
                [
                  // { required: true, message: 'Enter Emergency Contact Name' },
                ]
              }>
              <Input size='large' style={{ width: '80%' }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name='reason'
              label='FeedBack'
              rules={[{ required: true, message: 'Enter Feedback' }]}>
              <Input
                placeholder='Enter FeedBack'
                // allowClear
                style={{ width: '80%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='Safety Test Certificate'
              name='originalFileName'
              valuePropName='fileList'
              getValueFromEvent={normFile}
              type='file'
              // required
              // rules={[{ required: true, message: 'Upload Document' }]}
            >
              <Upload
                listType='picture'
                onPreview={handlePreview}
                type='file'
                accept='.pdf, image/*'
                maxCount={1}
                // beforeUpload={(file) => {
                //   const allowedTypes = [
                //     'application/pdf',
                //     'image/jpeg',
                //     'image/png',
                //     'image/jpg',
                //     'image/bmp',
                //   ];
                //   if (!allowedTypes.includes(file.type)) {
                //     notification.error(
                //       'You can only upload PDF and image files!'
                //     );
                //     return false; // Prevent the file from being uploaded
                //   }
                //   return true;
                // }}
                beforeUpload={(f, fl) => {
                  return false;
                }}>
                <Button icon={<UploadOutlined />}>
                  Safety Test Certificate
                </Button>
              </Upload>
            </Form.Item>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}>
              <img
                alt='example'
                style={{
                  width: '100%',
                }}
                src={previewImage}
              />
            </Modal>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item
              name='safetyTrainingFromDate'
              label='Safety Training From date'
              rules={[
                { required: true, message: 'Select Safety Training from date' },
              ]}>
              <DatePicker
                size='large'
                // style={{ width: '100%' }}
                format='DD-MM-YYYY'
                inputReadOnly={true} // Disable manual input
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='safetyTrainingToDate'
              label='Safety Training To date'
              rules={[
                { required: true, message: 'Select Safety Training To date' },
              ]}>
              <DatePicker
                size='large'
                // style={{ width: '100%' }}
                format='DD-MM-YYYY'
                inputReadOnly={true} // Disable manual input
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='safetyTrainingValidFromDate'
              label='Safety Training Valid From date'
              rules={[
                { required: true, message: 'Select Safety Training from date' },
              ]}>
              <DatePicker
                size='large'
                // style={{ width: '100%' }}
                format='DD-MM-YYYY'
                inputReadOnly={true} // Disable manual input
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='safetyCerticationEndDate'
              label='Safety Training Valid To date'
              rules={[
                { required: true, message: 'Select Safety Training To date' },
              ]}>
              <DatePicker
                size='large'
                // style={{ width: '100%' }}
                format='DD-MM-YYYY'
                inputReadOnly={true} // Disable manual input
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button onClick={(evt) => Reject(evt)}>Reject</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Safety;
