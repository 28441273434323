// import React, { useState, useEffect, useRef } from 'react';
// import { Table, Input, Space, Typography } from 'antd';
// import {
//   EyeFilled,
//   SearchOutlined,
//   InteractionOutlined,
// } from '@ant-design/icons';
// import axios from '../services/axiosInstance';
// import Highlighter from 'react-highlight-words';
// import Personneldetails from '../components/EmployeeOnboard/Personneldetails';
// import { Link } from 'react-router-dom';
// import useAuth from '../hooks/useAuth';
// import { setLoader } from '../actions';
// import { getEmployeeList, searchEmployee } from '../services/auth';
// import ReplaceEmployee from '../components/Contractor/replaceEmployee';
// import ColumnGroup from 'antd/es/table/ColumnGroup';

// import useConfig from 'antd/es/config-provider/hooks/useConfig';

// const Approver1 = () => {
//   const [sortedInfo, setSortedInfo] = useState({});
//   const [Employee, setEmployee] = useState([]);
//   const [loading, setloading] = useState(true);
//   // Search icon
//   const [searchText, setSearchText] = useState('');
//   const [searchedColumn, setSearchedColumn] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);
//   const [totalCount, setTotalCount] = useState(0);
//   const searchInput = useRef(null);
//   const { user, userConfig } = useAuth();
//   const [replaceOpen, setReplaceOpen] = React.useState(false);
//   const [selectedContractor, setSelectedContractor] = useState({});
//   const [Supplier, setSupplier] = React.useState([]);
//   const [selectedContractorCode, setSelectedContractorCode] = useState(null);
//   const [selectedContractorName, setSelectedContractorName] = useState(null);

//   const location = useConfig?.locationId;

//   // useEffect(() => {
//   //   getEmpList(pageSize, currentPage);
//   // }, []);

//   // const getEmpList = async (pageSize, currentPage) => {
//   //   setLoader(true);
//   //   getEmployeeList(pageSize, currentPage)
//   //     .then((response) => {
//   //
//   //       setLoader(false);
//   //       // setloading(false);
//   //       setTotalCount(response?.data?.count);

//   //       setEmployee(
//   //         response.data.passList.map((row) => {
//   //           return {
//   //             key: row.id,
//   //             id: row._id,
//   //             contractorName: row.jobDetails?.contractorName,
//   //             contractorId: row.jobDetails?.contractorCode,
//   //             firstName:
//   //               row.personalDetails?.firstName +
//   //               ' ' +
//   //               row.personalDetails?.lastName,
//   //             mobile: row.personalDetails?.mobile,
//   //             aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',
//   //             createdBy: row?.createdBy,
//   //             workMenCode: row?.personalDetails?.empCode,
//   //             status: row.status[0].toUpperCase() + row.status.slice(1),
//   //             locationId: row.locationId,
//   //             Edited: row.Edited,
//   //           };
//   //         })
//   //       );
//   //     })
//   //     .catch((error) => {
//   //       setLoader(false);
//   //     });
//   // };

//   useEffect(() => {
//     getEmpList(pageSize, currentPage);
//   }, []);

//   const getEmpList = async (pageSize, currentPage) => {
//     setLoader(true);
//     getEmployeeList(pageSize, currentPage)
//       .then((response) => {
//         setLoader(false);
//         // setloading(false);
//         setTotalCount(response?.data?.count);

//         setEmployee(
//           response.data.passList.map((row) => {
//             return {
//               key: row.id,
//               id: row._id,
//               _id: row?._id,
//               contractorName: row.jobDetails?.contractorName,
//               contractorId: row.jobDetails?.contractorCode,
//               firstName:
//                 row.personalDetails?.firstName +
//                 ' ' +
//                 row.personalDetails?.lastName,
//               mobile: row?.personalDetails?.mobile,
//               aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',
//               createdBy: row?.createdBy,
//               workMenCode: row?.personalDetails?.empCode,
//               status: row?.status[0]?.toUpperCase() + row?.status?.slice(1),
//               locationId: row?.locationId,
//               Edited: row?.Edited,
//               employementType: row?.employementType,
//               epnNumber: row?.personalDetails?.epnNumber,
//             };
//           })
//         );
//       })
//       .catch((error) => {
//         setLoader(false);
//       });
//   };

//   const handleSearch = (selectedKeys, confirm, dataIndex) => {
//     confirm();
//     setSearchText(selectedKeys[0]);
//     setSearchedColumn(dataIndex);
//   };

//   const SearchEmployeeData = async (search) => {
//     searchEmployee(pageSize, currentPage, search).then((response) => {
//       setLoader(false);
//       // setloading(false);
//       setTotalCount(response?.data?.count);

//       setEmployee(
//         response.data.passList.map((row) => {
//           return {
//             key: row.id,
//             id: row._id,
//             firstName:
//               row.personalDetails?.firstName +
//               ' ' +
//               row?.personalDetails?.lastName,
//             mobile: row?.personalDetails?.mobile,
//             aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',
//             createdBy: row?.personalDetails?.empCode,
//             status: row?.status[0]?.toUpperCase() + row?.status?.slice(1),
//             locationId: row?.locationId,
//           };
//         })
//       );
//     });
//   };

//   const handleReset = (clearFilters) => {
//     clearFilters();
//     setSearchText('');
//   };

//   const getColumnSearchProps = (dataIndex) => ({
//     filterDropdown: ({
//       setSelectedKeys,
//       selectedKeys,
//       confirm,
//       clearFilters,
//       close,
//     }) => (
//       <div
//         style={{
//           padding: 8,
//         }}
//         onKeyDown={(e) => e.stopPropagation()}>
//         <Input
//           ref={searchInput}
//           placeholder={`Enter atleast 3 character`}
//           value={selectedKeys[0]}
//           onChange={(e) => {
//             if (e.target.value.length >= 3) {
//               SearchEmployeeData(e.target.value);
//             } else if (
//               e.target.value.length === 0 ||
//               e.target.value.length === 2
//             ) {
//               getEmpList(pageSize, currentPage);
//             }
//             // setSelectedKeys(e.target.value ? [e.target.value] : []);
//           }}
//           onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
//           style={{
//             marginBottom: 8,
//             display: 'block',
//           }}
//         />
//         {/* <Space>
//           <Button
//             type='primary'
//             onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
//             icon={<SearchOutlined />}
//             size='small'
//             style={{
//               width: 90,
//             }}
//           >
//             Search
//           </Button>
//           <Button
//             onClick={() => clearFilters && handleReset(clearFilters)}
//             size='small'
//             style={{
//               width: 90,
//             }}
//           >
//             Reset
//           </Button>
//           <Button
//             type='link'
//             size='small'
//             onClick={() => {
//               close();
//             }}
//           >
//             close
//           </Button>
//         </Space> */}
//       </div>
//     ),
//     filterIcon: (filtered) => (
//       <SearchOutlined
//         style={{
//           color: filtered ? '#1890ff' : undefined,
//         }}
//       />
//     ),
//     onFilter: (value, record) =>
//       record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
//     onFilterDropdownOpenChange: (visible) => {
//       if (visible) {
//         setTimeout(() => searchInput.current?.select(), 100);
//       }
//     },
//     render: (text) =>
//       searchedColumn === dataIndex ? (
//         <Highlighter
//           highlightStyle={{
//             backgroundColor: '#ffc069',
//             padding: 0,
//           }}
//           searchWords={[searchText]}
//           autoEscape
//           textToHighlight={text ? text.toString() : ''}
//         />
//       ) : (
//         text
//       ),
//   });

//   const replaceContractor = (record) => {
//     setSelectedContractor(record.id);
//     setSelectedContractorCode(record.contractorId);

//     setSelectedContractorName(record.contractorName);
//     setReplaceOpen(true);
//   };

//   const columns = [
//     {
//       title: 'Name',
//       dataIndex: 'firstName',
//       key: 'firstName',
//       ...getColumnSearchProps('firstName'),

//       filterMode: 'tree',
//       filterSearch: true,
//       sortOrder: sortedInfo.columnKey === 'firstName' ? sortedInfo.order : null,
//       ellipsis: true,
//     },
//     {
//       title: 'EPN Number',
//       dataIndex: 'epnNumber',
//       key: 'epnNumber',
//     },
//     {
//       title: 'Aadhaar No',
//       dataIndex: 'aadharNo',
//       key: 'aadharNo',
//     },
//     {
//       title: 'Workman Code ',
//       dataIndex: 'workMenCode',
//       key: 'workMenCode',
//     },
//     {
//       title: 'Status',
//       dataIndex: 'status',
//       key: 'status',
//       ...getColumnSearchProps('status'),

//       render: (status) => {
//         return status === 'Approved' ? 'Active' : status;
//       },

//       filterMode: 'tree',
//       filterSearch: true,
//       width: '17%',
//       sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
//       ellipsis: true,
//     },
//     // {
//     //   // title: 'Pending Task',
//     //   title: 'Transaction Status',
//     //   dataIndex: 'approvalStage',
//     //   key: 'approvalStage',
//     //   render: (stage, record) => {
//     //     if (stage === 0) {
//     //       return (
//     //         <>
//     //           ER <br />
//     //           Medical
//     //           <br />
//     //           Safety
//     //         </>
//     //       );
//     //     } else if (stage === 1) {
//     //       return (
//     //         <>
//     //           Medical
//     //           <br />
//     //           Safety
//     //         </>
//     //       );
//     //     } else if (stage === 2) {
//     //       return 'Safety';
//     //     } else {
//     //       const employeeWithEditedFlag = Employee.find((employee) => {
//     //         // employee._id === record.key
//     //
//     //
//     //       });

//     //       if (
//     //         employeeWithEditedFlag &&
//     //         employeeWithEditedFlag.Edited === 'Yes'
//     //       ) {
//     //         return 'Profile updated. Approval pending with IR.';
//     //       } else {
//     //         return 'Approved & Synched With WFD';
//     //       }
//     //     }
//     //   },
//     // },

//     {
//       title: 'Location Id',
//       dataIndex: 'locationId',
//       key: 'locationId',
//     },

//     // {
//     //   title: 'Actions',
//     //   dataIndex: 'id',
//     //   render: (id, record) => {
//     //     if (userConfig.level === 2) {
//     //       return (
//     //         <Typography>
//     //           <Link to={`/home/approver/employee/${id}/safety`}>
//     //             <EyeFilled />
//     //           </Link>
//     //         </Typography>
//     //       );
//     //     } else {
//     //       return (
//     //         <Space>
//     //           <Typography>
//     //             <Link to={`/home/approver/employee/${id}`}>
//     //               <EyeFilled />
//     //             </Link>
//     //           </Typography>

//     //           <Typography.Link onClick={() => replaceContractor(record)}>
//     //             <InteractionOutlined />{' '}
//     //           </Typography.Link>
//     //         </Space>
//     //       );
//     //     }
//     //   },
//     // },
//     {
//       title: 'Actions',
//       dataIndex: 'id',
//       render: (id, record) => {
//         if (userConfig.level === 2) {
//           return (
//             <Typography>
//               <Link to={`/home/approver/employee/${id}/medical`}>
//                 <EyeFilled />
//               </Link>
//             </Typography>
//           );
//         } else if (userConfig.level === 3) {
//           // Add your logic for userConfig.level === 3
//           // For example:
//           return (
//             <Typography>
//               <Link to={`/home/approver/employee/${id}/safety`}>
//                 <EyeFilled />
//               </Link>
//             </Typography>
//           );
//         } else {
//           return (
//             <Space>
//               <Typography>
//                 <Link to={`/home/approver/employee/${id}`}>
//                   <EyeFilled />
//                 </Link>
//               </Typography>

//               <Typography.Link
//                 onClick={() => replaceContractor(record)}
//                 hidden={record.status !== 'Approved'}>
//                 <InteractionOutlined />
//               </Typography.Link>
//               {/* {filteredData.map((filter) => {

//                 <Typography.Link
//                   key={filter.id}
//                   // hidden={filter.status === 'Approved'}
//                   onClick={() => replaceContractor(record)}>
//                   <InteractionOutlined  />
//                 </Typography.Link>;
//               })} */}
//             </Space>
//           );
//         }
//       },
//     },
//   ];
//   const onChange = (pagination, filters, sorter, extra) => {
//     setSortedInfo(sorter);
//   };

//   let filteredData;
//   if (userConfig.role == 'approver') {
//     filteredData = Employee.filter((supplier) => {
//       // return supplier.employementType === "Contractor";
//       return (
//         supplier.employementType === 'Contractor' &&
//         userConfig.locationId === supplier.locationId
//       );
//     });
//   } else {
//     filteredData = Employee;
//   }

//   return (
//     <div>
//       <h1>
//         <b>Workmen List</b>
//       </h1>
//       <Table
//         columns={columns}
//         dataSource={filteredData}
//         onChange={onChange}
//         pagination={{
//           current: currentPage,
//           pageSize: pageSize,
//           total: totalCount,
//           // total: Employee.some(
//           //   (emp) => emp.locationId === userConfig.locationId
//           // )
//           //   ? totalCount
//           //   : 0,

//           onChange: async (page, pageSize) => {
//             setCurrentPage(page);
//             setPageSize(pageSize);
//             // Fetch data for the new page and pageSize
//             getEmpList(pageSize, page);
//           },
//         }}
//       />
//       <ReplaceEmployee
//         open={replaceOpen}
//         onCancel={() => setReplaceOpen(false)}
//         initialValues={selectedContractor}
//         contractorsList={Employee}
//         contractorName={selectedContractorName}
//         contractorCode={selectedContractorCode}
//       />
//     </div>
//   );
// };

// export default Approver1;

//Mallikarjun Code
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Table, Input, Space, Typography } from 'antd';
import {
  EyeFilled,
  SearchOutlined,
  InteractionOutlined,
} from '@ant-design/icons';
import axios from '../services/axiosInstance';
import Highlighter from 'react-highlight-words';
import Personneldetails from '../components/EmployeeOnboard/Personneldetails';
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { setLoader } from '../actions';
import { getEmployeeList, searchEmployee } from '../services/auth';
import ReplaceEmployee from '../components/Contractor/replaceEmployee';
import ColumnGroup from 'antd/es/table/ColumnGroup';

import useConfig from 'antd/es/config-provider/hooks/useConfig';

const Approver1 = () => {
  const [sortedInfo, setSortedInfo] = useState({});
  const [Employee, setEmployee] = useState([]);
  console.log('Employee', Employee);
  const [loading, setLoading] = useState(true);
  // Search icon
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const searchInput = useRef(null);
  const { user, userConfig } = useAuth();
  const [replaceOpen, setReplaceOpen] = React.useState(false);
  const [selectedContractor, setSelectedContractor] = useState({});
  const [employementType, setemployementType] = useState('Contractor');
  const [Supplier, setSupplier] = React.useState([]);
  const [selectedContractorCode, setSelectedContractorCode] = useState(null);
  const [selectedContractorName, setSelectedContractorName] = useState(null);

  const location = useConfig?.locationId;
  //const role = userConfig?.role;
  useEffect(() => {
    getEmpList(pageSize, currentPage, employementType);
  }, [pageSize, currentPage, employementType]);

  // const getEmpList = async (pageSize, currentPage,employementType) => {
  //   setLoader(true);
  //   getEmployeeList(pageSize, currentPage,employementType)
  //     .then((response) => {
  //       setLoader(false);
  //       // setLoading(false);
  //       setTotalCount(response?.data?.count);

  //       setEmployee(
  //         response.data.passList.map((row) => {
  //           return {
  //             key: row.id,
  //             id: row._id,
  //             _id: row?._id,
  //             contractorName: row.jobDetails?.contractorName,
  //             contractorId: row.jobDetails?.contractorCode,
  //             firstName:
  //               row.personalDetails?.firstName +
  //               ' ' +
  //               row.personalDetails?.lastName,
  //             mobile: row?.personalDetails?.mobile,
  //             aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',
  //             createdBy: row?.createdBy,
  //             workMenCode: row?.personalDetails?.empCode,
  //             status: row?.status[0]?.toUpperCase() + row?.status?.slice(1),
  //             locationId: row?.locationId,
  //             Edited: row?.Edited,
  //             employementType: row?.employementType,
  //             epnNumber: row?.personalDetails?.epnNumber,
  //           };
  //         })
  //       );
  //     })
  //     .catch((error) => {
  //       setLoader(false);
  //     });
  // };
  const getEmpList = async (pageSize, currentPage, employmentType) => {
    setLoading(true);
    try {
      const response = await getEmployeeList(
        pageSize,
        currentPage,
        employmentType
      );
      setLoading(false);
      setTotalCount(response?.data?.count);
      setEmployee(
        response.data.passList.map((row) => ({
          key: row.id,
          id: row._id,
          _id: row?._id,
          contractorName: row.jobDetails?.contractorName,
          contractorId: row.jobDetails?.contractorCode,
          firstName:
            row.personalDetails?.firstName +
            ' ' +
            row.personalDetails?.lastName,
          mobile: row?.personalDetails?.mobile,
          aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',
          createdBy: row?.createdBy,
          workMenCode: row?.personalDetails?.empCode,
          status: row?.status[0]?.toUpperCase() + row?.status?.slice(1),
          locationId: row?.locationId,
          Edited: row?.Edited,
          employmentType: row?.employmentType,
          epnNumber: row?.personalDetails?.epnNumber,
          approvalStage: row?.approvalStage,
          medicalException: row?.exceptions?.Medical?.includes(
            row?.jobDetails?.designation
          ),
          safetyException: row?.exceptions?.Safety?.includes(
            row?.jobDetails?.designation
          ),
        }))
      );
    } catch (error) {
      setLoading(false);
    }
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // const SearchEmployeeData = async (search) => {
  //   searchEmployee(pageSize, currentPage, search).then((response) => {
  //     setLoader(false);
  //     // setLoading(false);
  //     setTotalCount(response?.data?.count);

  //     setEmployee(
  //       response.data.passList.map((row) => {
  //         return {
  //           key: row.id,
  //           id: row._id,
  //           firstName:
  //             row.personalDetails?.firstName +
  //             ' ' +
  //             row?.personalDetails?.lastName,
  //           mobile: row?.personalDetails?.mobile,
  //           aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',
  //           createdBy: row?.personalDetails?.empCode,
  //           status: row?.status[0]?.toUpperCase() + row?.status?.slice(1),
  //           locationId: row?.locationId,
  //         };
  //       })
  //     );
  //   });
  // };
  const SearchEmployeeData = async (search) => {
    try {
      const response = await searchEmployee(pageSize, currentPage, search);
      setLoading(false);
      setTotalCount(response?.data?.count);
      setEmployee(
        response.data.passList.map((row) => ({
          key: row.id,
          id: row._id,
          firstName:
            row.personalDetails?.firstName +
            ' ' +
            row?.personalDetails?.lastName,
          mobile: row?.personalDetails?.mobile,
          aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',
          createdBy: row?.personalDetails?.empCode,
          status: row?.status[0]?.toUpperCase() + row?.status?.slice(1),
          locationId: row?.locationId,
        }))
      );
    } catch (error) {
      setLoading(false);
    }
  };
  const handleReset = (clearFilters, employmentType) => {
    clearFilters();
    setSearchText('');
    getEmpList(pageSize, currentPage, employmentType);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Enter atleast 3 character`}
          value={selectedKeys[0]}
          onChange={(e) => {
            if (e.target.value.length >= 3) {
              SearchEmployeeData(e.target.value);
            } else if (
              e.target.value.length === 0 ||
              e.target.value.length === 2
            ) {
              getEmpList(pageSize, currentPage, employementType);
            }
            // setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <a
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginRight: 8 }}>
            Search
          </a>
          <a onClick={() => handleReset(clearFilters)}>Reset</a>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const replaceContractor = (record) => {
    console.log('record757', record);
    setSelectedContractor(record.id);
    setSelectedContractorCode(record.contractorId);
    setSelectedContractorName(record.contractorName);
    setReplaceOpen(true);
  };

  // console.log('Employee764 is', Employee[0].contractorName);
  // console.log('Employee765 is', Employee[0].contractorCode);

  // const columns = [
  //   {
  //     title: 'Name',
  //     dataIndex: 'firstName',
  //     key: 'firstName',
  //     ...getColumnSearchProps('firstName'),
  //     filterMode: 'tree',
  //     filterSearch: true,
  //     sortOrder: sortedInfo.columnKey === 'firstName' ? sortedInfo.order : null,
  //     ellipsis: true,
  //   },
  //   {
  //     title: 'EPN Number',
  //     dataIndex: 'epnNumber',
  //     key: 'epnNumber',
  //   },
  //   {
  //     title: 'Aadhaar No',
  //     dataIndex: 'aadharNo',
  //     key: 'aadharNo',
  //   },
  //   {
  //     title: 'Workman Code ',
  //     dataIndex: 'workMenCode',
  //     key: 'workMenCode',
  //   },
  //   {
  //     title: 'Status',
  //     dataIndex: 'status',
  //     key: 'status',
  //     ...getColumnSearchProps('status'),
  //     render: (status) => (status === 'Approved' ? 'Active' : status),
  //     filterMode: 'tree',
  //     filterSearch: true,
  //     width: '17%',
  //     sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
  //     ellipsis: true,
  //   },
  //   {
  //     title: 'Location Id',
  //     dataIndex: 'locationId',
  //     key: 'locationId',
  //   },
  //   {
  //     title: 'Actions',
  //     dataIndex: 'id',
  //     render: (id, record) => {
  //       if (userConfig.level === 2) {
  //         return (
  //           <Typography>
  //             <Link to={`/home/approver/employee/${id}/medical`}>
  //               <EyeFilled />
  //             </Link>
  //           </Typography>
  //         );
  //       } else if (userConfig.level === 3) {
  //         return (
  //           <Typography>
  //             <Link to={`/home/approver/employee/${id}/safety`}>
  //               <EyeFilled />
  //             </Link>
  //           </Typography>
  //         );
  //       } else {
  //         return (
  //           <Space>
  //             <Typography>
  //               <Link to={`/home/approver/employee/${id}`}>
  //                 <EyeFilled />
  //               </Link>
  //             </Typography>
  //             <Typography.Link
  //               onClick={() => replaceContractor(record)}
  //               hidden={record.status !== 'Approved'}>
  //               <InteractionOutlined />
  //             </Typography.Link>
  //           </Space>
  //         );
  //       }
  //     },
  //   },
  // ];

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'firstName',
        key: 'firstName',
        ...getColumnSearchProps('firstName'),
        filterMode: 'tree',
        filterSearch: true,
        sortOrder:
          sortedInfo.columnKey === 'firstName' ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        title: 'EPN Number',
        dataIndex: 'epnNumber',
        key: 'epnNumber',
      },
      {
        title: 'Aadhaar No',
        dataIndex: 'aadharNo',
        key: 'aadharNo',
      },
      {
        title: 'Workman Code ',
        dataIndex: 'workMenCode',
        key: 'workMenCode',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        ...getColumnSearchProps('status'),
        render: (status) => (status === 'Approved' ? 'Active' : status),
        filterMode: 'tree',
        filterSearch: true,
        width: '17%',
        sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        // title: 'Pending Task',
        title: 'Transaction Status',
        dataIndex: 'approvalStage',
        key: 'approvalStage',
        render: (stage, record) => {
          //debugger;
          if (stage === 0) {
            return (
              <>
                IR <br />
                {!record?.safetyException && 'Safety'}
                <br />
                {!record?.medicalException && 'Medical'}
              </>
            );
          } else if (stage === 1) {
            return (
              <>
                {!record?.safetyException && 'Safety'}
                <br />
                {!record?.medicalException && 'Medical'}
              </>
            );
          } else if (stage === 2 && !record?.medicalException) {
            // return 'Safety';
            return 'Medical';
          } else {
            // const employeeWithEditedFlag = Employee.find(
            //   (employee) => employee._id === record.key
            // );

            // if (
            //   employeeWithEditedFlag &&
            //   employeeWithEditedFlag.Edited === 'Yes'
            // ) {
            if (record.Edited == 'Yes') {
              return 'Profile updated. Approval pending with IR.';
            } else {
              return 'Approved & Synched With WFD';
            }
          }
        },
      },
      {
        title: 'Location Id',
        dataIndex: 'locationId',
        key: 'locationId',
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (id, record) => {
          if (userConfig.level === 2) {
            return (
              <Typography>
                <Link to={`/home/approver/employee/${id}/safety`}>
                  <EyeFilled />
                </Link>
              </Typography>
            );
          } else if (userConfig.level === 3) {
            return (
              <Typography>
                <Link to={`/home/approver/employee/${id}/medical`}>
                  <EyeFilled />
                </Link>
              </Typography>
            );
          } else {
            return (
              <Space>
                <Typography>
                  <Link to={`/home/approver/employee/${id}`}>
                    <EyeFilled />
                  </Link>
                </Typography>
                <Typography.Link
                  onClick={() => replaceContractor(record)}
                  hidden={record.status !== 'Approved'}>
                  <InteractionOutlined />
                </Typography.Link>
              </Space>
            );
          }
        },
      },
    ],
    [sortedInfo, searchText, searchedColumn, userConfig]
  );
  const onChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  // const handleReplaceCancel = (employmentType) => {
  //   setReplaceOpen(false);
  //   getEmpList(pageSize, currentPage, employmentType);
  // };
  const { role } = userConfig;

  //let filteredData = Array.isArray(Employee) ? Employee : [];
  //console.log('Employee State:', Employee);

  //console.log('Filtered Data:', filteredData);
  //console.log('Total Count of Filtered Data:', filteredData.length);
  // const startIndex = (currentPage - 1) * pageSize;
  // const endIndex = currentPage * pageSize;
  // const paginatedData = filteredData.slice(startIndex, endIndex);
  let filteredData = Array.isArray(Employee) ? Employee : [];
  const startIndex = (currentPage - 1) * pageSize;
  const paginatedData = filteredData.slice(startIndex, startIndex + pageSize);

  return (
    <div>
      <h1>
        <b>Workmen List</b>
      </h1>
      <Table
        columns={columns}
        dataSource={Employee}
        loading={loading}
        onChange={onChange}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalCount,
          // showSizeChanger: true,
          // pageSizeOptions: ['10', '20', '50', '100'],
          onChange: async (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
            // Fetch data for the new page and pageSize
            // EmployeeList(pageSize, page);
          },
        }}
      />
      <ReplaceEmployee
        open={replaceOpen}
        onCancel={() => setReplaceOpen(false)}
        initialValues={selectedContractor}
        contractorsList={Employee}
        contractorName={selectedContractorName}
        // contractorName={Employee?.contractorName}
        contractorCode={selectedContractorCode}
        // contractorCode={Employee?.contractorCode}
      />
    </div>
  );
};

export default Approver1;
